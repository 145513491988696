<script>
import { regexHelper } from '@/mixins/regexHelper'

/**
 * TextInput is a pre styled input text element.
 * It will throw an @input-submit event on pressing enter or clicking the submit icon, an @input-change event when input value changes,
 * an @input-blurred event when losing focus after the input value has changed, an @input-lost-focus any time the input blurs and an @input-close event on pressing esc.
 * - id: Should be provided in order to get full functionality. Without an id you won't get the ability to close on esc and instant focus the input field when opening.
 * - label (optional): Text displayed as the label of the input field.
 * - tinyLabel (optional): Small label displayed in the top border of the input. If set there will be a border around the whole input field.
 * - defaultValue (optional): The default value of the input. For editing purposes for example.
 * - placeholder (optional): Text shown if the input is empty.
 * - submitButton (default: true): Determines whether there is a submit button.
 * - resetOnSubmit (default: false): If true, the value will be reset to default or null after submit.
 * - iconClass (required if submitButton): A font awesome icon class.
 * - isDisabled (default: false): If true, the user can't change the input value.
 * - isAutoFocused (default: false): If true, the input field will get focused automatically when it is rendered.
 * - showUnsavedChanges (default: false): If true, the input will highlight if it's value differs from the last submitted one.
 * - maxLength: Set the maximum char length of the input.
 * - pattern: (Regular Expression pattern as string): Only allow a specific input format.
 * - patternKey: Like pattern, but you do not specify a pattern directly, but specify one that is stored under a key with RegexHelper. If both pattern and patternKey are defined, patternKey is ignored.
 **/
export default {
    name: 'TextInput',
    // @input-submit: Event emitted on submit | returns the event and current input value
    // @input-change: Event emitted on input | returns current input value
    // @input-blurred: Event emitted on blur only if value has changed | returns current input value
    // @input-lost-focus: Event always emitted on blur | returns current input value
    // @input-close: Event emitted on pressing esc key | no return
    // Note: We do not declare the emits here, because we want to have the registrations as part of $attrs
    //       emits: ['input-submit', 'input-change', 'input-blurred', 'input-lost-focus', 'input-close'],
    props: {
        id: [String, Number],
        label: String,
        tinyLabel: String,
        defaultValue: [String, Number],
        placeholder: String,
        submitButton: {
            default: true,
            type: Boolean
        },
        resetOnSubmit: {
            default: false,
            type: Boolean
        },
        iconClass: String,
        isDisabled: {
            default: false,
            type: Boolean
        },
        isAutoFocused: {
            default: false,
            type: Boolean
        },
        showUnsavedChanges: {
            default: false,
            type: Boolean
        },
        maxLength: Number,
        pattern: String,
        patternKey: String,
        initiallyValid: {
            default: true,
            type: Boolean
        }
    },
    mixins: [
        regexHelper
    ],
    data () {
        return {
            inputValue: this.defaultValue || '',
            lastSubmittedValue: this.defaultValue || '',
            patternMatched: this.initiallyValid
        }
    },
    methods: {
        focusInputElement () {
            document.getElementById(`c_text-input_${this.id}`).focus()
        },

        keyPressedHandler (event) {
            if (event.key === 'Enter') {
                this.emitSubmit(event)
            } else if (event.key === 'Escape') {
                this.$emit('input-close')
            }
        },

        emitSubmit (event) {
            // We actually need to check for listeners as the lastSubmittedValue must not be
            // updated if there is no listener which performs the actual submit
            //
            // see https://git.cornelsen.de/pub-ops/corflow/frontend/-/merge_requests/593#note_316549
            //     for further information
            if (this.$attrs.onInputSubmit) {
                this.emit(event, 'input-submit', true)
                this.updateLastSubmittedValueAndReset(true)
            }
        },

        emitBlur () {
            // We actually need to check for listeners as the lastSubmittedValue must not be
            // updated if there is no listener which performs the actual submit
            //
            // see https://git.cornelsen.de/pub-ops/corflow/frontend/-/merge_requests/593#note_316549
            //     for further information
            if (this.$attrs.onInputBlurred) {
                this.emit(null, 'input-blurred')
                this.$emit('input-lost-focus')
                this.updateLastSubmittedValueAndReset(false)
            }
        },

        emit (event, name, alwaysEmit) {
            if (event) {
                event.stopPropagation()
            }
            if ((this.hasUnsavedChanges || alwaysEmit) && this.patternMatched) {
                this.$emit(name, {
                    event: event,
                    value: this.inputValue
                })
            }
        },

        updateLastSubmittedValueAndReset (doResetInput) {
            this.lastSubmittedValue = this.inputValue
            if (doResetInput && this.resetOnSubmit) {
                this.inputValue = this.defaultValue || ''
            }
        }
    },
    computed: {
        hasUnsavedChanges () {
            return this.inputValue !== this.lastSubmittedValue
        },

        getMaxLengthCount () {
            return this.$t('textInput.maxLengthCounter', [this.inputValue.length, this.maxLength])
        }
    },
    watch: {
        inputValue () {
            if (this.pattern) {
                this.patternMatched = new RegExp(this.pattern.toString(), 'g').test(this.inputValue)
            } else if (this.patternKey) {
                this.patternMatched = this.checkForPatternKey(this.patternKey, this.inputValue)
            }
            this.emit(null, 'input-change')
        },
        defaultValue () {
            this.inputValue = this.defaultValue || ''
            this.lastSubmittedValue = this.defaultValue || ''
        }
    },
    mounted () {
        if (this.isAutoFocused) {
            this.focusInputElement()
        }
    }
}
</script>

<template>
    <div class="c_text-input-wrapper generals-input-wrapper">
        <label v-if="label"
               class="c_text-input-label generals-input-label">
            <span>{{label}}</span>
        </label>
        <div class="c_text-input-container generals-input-container">
            <div v-if="tinyLabel"
                 class="c_text-input-tiny-label generals-input-tiny-label">
                <span>{{tinyLabel}}</span>
            </div>
            <input v-bind:id="id ? `c_text-input_${id}`: null"
                   type="text"
                   class="c_text-input generals-input"
                   v-bind:disabled="isDisabled"
                   v-bind:class="{
                       'm--no-icon': !submitButton && !iconClass,
                       'm--unsaved': showUnsavedChanges && hasUnsavedChanges
                   }"
                   v-bind:placeholder="placeholder || $tc('textInput.defaultPlaceholder')"
                   v-bind:maxlength="maxLength"
                   v-on:blur="emitBlur()"
                   v-on:click="$event.stopPropagation()"
                   v-on:keyup="keyPressedHandler($event)"
                   v-model="inputValue" />
            <button v-if="!isDisabled && (submitButton || iconClass)"
                    tabindex="-1"
                    class="c_text-input-submit"
                    v-bind:class="{'m--disabled': !submitButton}"
                    v-on:mousedown="$event.preventDefault()"
                    v-on:click="emitSubmit($event)">
                <span class="c_text-input-icon"
                      v-bind:class="iconClass">
                </span>
            </button>
            <div v-if="maxLength"
                 class="c_text-input-max-length-indicator generals-input-max-length-indicator"
                 v-bind:class="{
                     'm--close-to-max': inputValue.length >= maxLength * 0.9,
                     'm--max-reached': inputValue.length === maxLength
                 }">
                <span>{{getMaxLengthCount}}</span>
            </div>
            <div v-if="!patternMatched"
                 class="c_text-input-error-message generals-input-error-message">
                <span>{{$tc('textInput.patternDoesNotMatch')}}</span>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.c_text-input-wrapper {

    .c_text-input-label {
    }

    .c_text-input-container {

        .c_text-input-tiny-label {
        }

        .c_text-input {
        }

        .c_text-input-submit {

            .c_text-input-icon {
            }
        }

        .c_text-input-max-length-indicator {
            position: absolute;
            right: 0;
        }

        .c_text-input-error-message {
        }
    }
}
</style>
