/**
 * A reusable service for handling user. Provides function to get the list of users.
 * Works as a singleton. If the list of users is already existing it will only return the list.
 * Otherwise, it will get the list of users from keycloak.
 *
 * @property {String} serviceProviderCompany
 **/
export const userHandler = {
    methods: {
        initListOfUsers () {
            return this.axiosGet(
                'users',
                this.$tc('userHandler.getUsersError'))
                .then(users => {
                    users.forEach(user => {
                        const fullName = `${user.firstName} ${user.lastName}`
                        user.fullNameWithUsername = user.serviceProviderCompany
                            ? `${fullName} (${user.userName} | ${user.serviceProviderCompany})`
                            : `${fullName} (${user.userName})`
                    })
                    this.$global.user.list = users
                })
                .catch(() => {})
        },

        getListOfUsers () {
            return this.$global.user.list || []
        },

        getUserRoles () {
            return this.$global.keycloak.realmAccess && this.$global.keycloak.realmAccess.roles
                ? this.$global.keycloak.realmAccess.roles
                : []
        },

        initUserSettings () {
            return this.axiosGet(
                'user',
                this.$tc('userHandler.getUserSettingsError'))
                .then(user => {
                    this.$global.user.current = setupUserSettings(user)
                    setupNotificationDefaults(this)
                    return Promise.resolve(true)
                })
                .catch(() => {
                    return this.$global.keycloak.loadUserProfile()
                        .then(profile => {
                            this.$global.user.current = setupUserSettings(profile)
                            setupNotificationDefaults(this)
                            return Promise.resolve(true)
                        })
                })
        },

        getUserName () {
            return this.$global.user.current.userName
        },

        getUserEmail () {
            return this.$global.user.current.email
        },

        getUserHasRightToImportProduct () {
            return this.$global.user.current.hasRightToImportProduct
        },

        getUserFirstLastName () {
            return `${this.$global.user.current.firstName || ''} ${this.$global.user.current.lastName || ''}`.trim()
        },

        getUserFirstName () {
            const firstName = this.$global.user.current.firstName || this.getUserName().split('.')[0]
            return firstName.charAt(0).toUpperCase() + firstName.slice(1)
        },

        getUserFullName (reversed) {
            if (!this.$global.user.current.firstName || !this.$global.user.current.lastName) {
                return this.getUserName()
            }
            return reversed
                ? `${this.$global.user.current.lastName}, ${this.$global.user.current.firstName}`
                : `${this.$global.user.current.firstName} ${this.$global.user.current.lastName}`
        },

        getUserSettingsParameter (parameterName) {
            return this.$global.user.current?.settings && this.$global.user.current.settings[parameterName]
                ? JSON.parse(this.$global.user.current.settings[parameterName])
                : {}
        },

        updateUserSettingsParameter (parameterName, value, errorMessage, successMessage) {
            const oldParameterValue = this.getUserSettingsParameter(parameterName)
            setUserSettingsParameter(this.$global.user.current, parameterName, value)
            return this.axiosPut(
                `user/parameter/${parameterName}`,
                value,
                errorMessage || this.$tc('userHandler.updateUserSettingsError'),
                successMessage)
                .then(() => {
                    return Promise.resolve(true)
                })
                .catch(error => {
                    setUserSettingsParameter(this.$global.user.current, parameterName, oldParameterValue)
                    return Promise.reject(error)
                })
        },

        getUserPreference (field) {
            const userPreferences = this.getUserSettingsParameter('userPreferences')
            return userPreferences[field]
        },

        /**
         * @param field preferences field to set
         * @param value optional value to set; if no value is provided, will toggle the existing value
         */
        setUserPreference (field, value) {
            const userPreferences = this.getUserSettingsParameter('userPreferences')
            const originalValue = userPreferences[field]
            const newValue = (value === null || value === undefined) ? !userPreferences[field] : value
            if (originalValue === newValue) {
                // nothing to do
                return
            }
            userPreferences[field] = newValue
            this.updateUserSettingsParameter(
                'userPreferences',
                userPreferences,
                this.$tc('mainNavigation.saveUserPreferencesError'),
                {
                    standard: this.$tc('mainNavigation.saveUserPreferencesSuccess.standard'),
                    short: this.$tc('mainNavigation.saveUserPreferencesSuccess.short')
                })
        },

        updateContactMailInKeycloak (value, errorMessage, successMessage) {
            return this.axiosPut('user/parameter/setEmail',
                { contactMail: value },
                errorMessage || this.$tc('userHandler.updateUserSettingsError'),
                successMessage)
                .then(() => {
                    if (this.$global.user.current) {
                        this.$global.user.current.email = value
                    }
                    return Promise.resolve(true)
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        }
    }
}

function setUserSettingsParameter (currentUser, parameterName, value) {
    if (currentUser?.settings) {
        currentUser.settings[parameterName] = JSON.stringify(value)
    }
}

export const userParameterKeyServiceList = 'serviceList'

function setupUserSettings (userProfile) {
    function setupUserSettingDefaults (parameters) {
        const keys = [
            'userPreferences', 'lastLocation',
            'productList', 'productList-set', 'productList-sort',
            'productBriefInformation-sort', 'productContents-sort', 'productDocuments-sort', 'productMembers-sort', 'productSchedule-sort',
            'productAssetOrders', 'productAssetOrders-sort',
            'taskList', 'taskList-set', 'taskList-sort',
            'hiddenTasksList', 'hiddenTasksList-set', 'hiddenTasksList-sort',
            'taskInputs-sort', 'taskOutputs-sort',
            'processMigration', 'processMigration-sort', 'processMigrationOverview-sort', 'processMigrationInstances-sort',
            'processModels', 'processModels-sort',
            'adminProducts', 'adminProducts-sort',
            'adminProductEvents', 'adminProductEvents-sort', 'adminFailedJobs-sort', 'adminCurrentTasks-sort',
            userParameterKeyServiceList
        ]
        keys.forEach(key => {
            parameters[key] = parameters[key] || '{}'
        })
        parameters.lastProducts = parameters.lastProducts || '[]'
        return parameters
    }
    return {
        userName: userProfile.userName || userProfile.username,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        email: userProfile.email,
        hasRightToImportProduct: userProfile.hasRightToImportProduct,
        settings: setupUserSettingDefaults(userProfile.parameters || {})
    }
}

function setupNotificationDefaults (context) {
    const notificationDefaults = {
        notificationNewProduct: true,
        notificationNewTask: true
    }
    const userPreferences = context.getUserSettingsParameter('userPreferences')
    let updated = false
    Object.keys(notificationDefaults).forEach(key => {
        if (userPreferences[key] === undefined) {
            userPreferences[key] = notificationDefaults[key]
            updated = true
        }
    })
    if (updated) {
        context.updateUserSettingsParameter('userPreferences', userPreferences, null, null)
    }
}
